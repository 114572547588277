.main-section {
    padding: 8% 10%;
    /* background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),url(http://localhost:3000/static/media/wallpaper.2f325b2c081b4c0a3ce7.jpg); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: lighten;
    animation: fadeIn;
    animation-duration: 2s;
    /* min-height: 100vh; */
    /* min-height: fit-content; */
    position: relative;
    background: antiquewhite
    /* background-image: url('../assets/images/bagan.gif'); */
}
.main-container {
    /* background: rgba(255, 255, 255, 0.2); */
    /* backdrop-filter: blur(4px); */

    display: flex;
    justify-content: center;
    margin-top: 16vh;
}
.Profile-pic {
    width: 240px;
    border-radius: 25%;
}
.main-container h3 {
    color: #373737;
    display: flex;
    flex-wrap: wrap;
    font-family: 'Advent Pro', sans-serif;
    font-weight: 600;
    backdrop-filter: blur(1px);
    width: fit-content;
    padding: 0 10px;

    font-size: 160px;
    margin-bottom: 20px;
    font-weight: 400;
}
/* .main-container h3 > span {
    color:#303030;
} */
.main-container div {
    font-size: 18px;
}
.tab {
    margin-left: 40px;
}
.main-container p > span {
    /* color: #ff6476; */
}
.more {
    font-size: 16px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    padding: 4px 10px;
    max-width: 200px;
    /* border-radius: 6px; */
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.blue {
    border: 1px solid #6bd4e3;
}
.orange {
    background: #d60038;
    border: 1px solid #fff;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 2.5s;
    animation-fill-mode: forwards;
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-duration: 0.5s;
}
.orange:hover {
    background: antiquewhite;
    border: 1px solid #d60038;
    color: #d60038;
    -webkit-transition-duration: 0.5s; /* Safari */
    transition-duration: 0.5s;
}
.main-container > .left-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
}
.main-container > .left-div > p {
    /* background: rgba(255, 255, 255, 0.8); */
    backdrop-filter: blur(1px);
    padding: 10px;
    /* padding: 40px; */
    width: 80%;
    color: #373737;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    font-weight: 300;
}
.main-container > .right-div {
    /* min-width: 45%; */
    display: flex;
    align-items: end;
    padding: 40px;
}
.tagcloud {
    color: #ff4040;
    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    /* animation-delay: 2s; */
    animation-fill-mode: forwards;
}
.link-logo {
    border: 1px solid #ff9200;
    padding: 10px;
    background: #ffd69f;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.25s ease-in-out;
    text-decoration: none;
    color: inherit;

    opacity: 0;
    animation: fadeIn;
    animation-duration: 2s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}
.link-logo:hover {
    color: #f7f0de;
    transform: rotate(-15deg);
}
.logo-wrap {
    display: flex;
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translate(-50%,-50%);
    flex-direction: column;
}
.link-logo:not(:last-child) {
    margin-bottom: 40px;
}
.cv-download {
    font-size: 16px;
    height: 40px;
    width: 300px;
    background: #ff7121;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-35deg);
    position: absolute;
    left: -60px;
    top: 100px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: 0.15s;
}
.cv-download:hover {
    font-size: 18px;
}
@media only screen and (max-width: 1400px) {
    .main-container h3 {
        font-size: 120px;
        margin-bottom: 6px;
    }
}
@media only screen and (max-width: 1150px) {
    .main-container h3 {
        font-size: 100px;
        margin-bottom: 6px;
    }
    .main-container .left-div > p {
        font-size: 16px;
    }
    .main-section {
        padding: 8% 0%;
    }
}
@media only screen and (max-width: 803px) {
    .main-container {
        flex-direction: column-reverse;
    }
    .Profile-pic {
        width: 100px;
    }
    .main-container > .right-div {
        justify-content: center;
    }
    .main-container .left-div > p {
        font-size: 14px;
    }
}
@media only screen and (max-width: 687px) {
    .main-container h3 {
        font-size: 80px;
        margin-bottom: 6px;
    }
    .link-logo {
        width: 30px;
        height: 30px;
        font-size: 12px;
    }
    /* .logo-wrap {
        bottom: 2rem;
        right: 2rem;
    } */
    .link-logo:not(:last-child) {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 405px) {
    .main-container h3 {
        font-size: 40px;
        margin-bottom: 6px;
        /* white-space: nowrap; */
    }
    .main-container .left-div {
        /* width: 80%; */
    }
}
/* @media only screen and (max-height: 550px) {
    .logo-wrap {
       position: unset;
       justify-content: flex-end;
       margin: 2rem 2rem 0 0;
    }

} */