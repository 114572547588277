.about-section {
    color: #1d1603;
    background: #ffe8c9;
    /* min-height: 100vh; */
    width: 100%;
    padding: 5% 10%;
    /* opacity: 0;
    transition: all 2s ease; */
    position: relative;
}
.about-section p {
    text-align: right;
}
.about-section h4 {
    margin-bottom: 12px;
    font-size: 40px;
    color: #d60038;
    position: absolute;
    transform: rotate(270deg);
    left: -100px;
    top: 50px;
}
.about-section h3{
    font-weight: 400;
    font-size: 60px;
    position: relative;
    border-bottom: 2px solid #d60139;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}
.active{
    transform: translate(0px, 0px);
    opacity: 1 !important;
}
/* .about-section::before {
    content: 'More';
    position: absolute;
    color: white;
    margin-top: -15px;
    transform: rotate(-30deg);
    margin-left: -25px;
    font-family: 'Advent Pro';
    font-weight: bold;
    font-size: 20px;

} */
.first-article {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    justify-content: center;
}
.first-article .left-div {
    width: 50%;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
    animation-delay: 0.2s;
}
.first-article .right-div {
    width: 30%;
    display: flex;
    justify-content: center;
}
.first-article .left-div, .first-article .right-div {
    margin: 0 40px;
}
.right-div {
    position: relative;
}
.right-div .me-pic {
    border-radius: 50%;
    overflow: hidden;
    width: 160px;
    height: 160px;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 1s ease;
    animation-delay: 0.2s;
    filter: drop-shadow(2px 4px 6px antiquewhite);
}
.me-pic:hover {
    filter: none;
}
.right-div > div > img {
    width: 200px;
}
.overlay {
    background: #8831b7a3;
    width: 200px;
    height: 200px;
    position: absolute;
    border-radius: 50%;
}
.overlay:hover {
    transform: scale(0.0);
    transition: 1s;
}
.first-article .left-div > span {
    /* color: #b81458; */
}

.second-article {
    display: flex;
    margin-top: 10rem;
    align-items: center;
    justify-content: center;
}
.ref-pic {
    width: 100px;
    position: absolute;
    z-index: 1;
    border: 2px solid white;
    transition: 0.6s;
    cursor: pointer;
    transform: rotate(-20deg);
}
.ref-pic:hover {
    transform: scale(350%) translateX(20px);
    z-index: 2;
}
.refTwo-pic {
    width: 140px;
    position: absolute;
    z-index: 1;
    border: 2px solid white;
    transition: 0.6s;
    cursor: pointer;
    margin-left: 70px;
    margin-top: -40px;
    transform: rotate(20deg);
}
.refTwo-pic:hover {
    transform: scale(350%) translateX(20px);
}
.own-pic {
    width: 200px;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 1s ease;
    animation-delay: 0.2s;
}
/* .own-pic:hover {
    transform: scale(250%) translateX(40px);
    z-index: 2;
} */
.second-article .left-div {
    width: 40%;
    position: relative;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 1s ease;
    animation-delay: 0.2s;
}
.second-article .right-div {
    width: 50%;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 1s ease;
    animation-delay: 0.2s;
}
.from {
    position: absolute;
    top: -25px;
    left: -25px;

    font-size: 10px;
}
.invi-box {
    position: relative;
    height: 200px;
    width: 200px;
}
.to {
    position: absolute;

    font-size: 10px;
    bottom: 0px;
    left: 250px;
}
/* .about-section p::first-letter {
    font-size: 200%;
} */
.about-skill {
    margin-top: 10rem;
    color: #cdcdcd;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.amdon-group {
    color: #d60038;
    box-shadow: inset 0 -3px 0 -2px #ff7121;
    transition: box-shadow .1s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}
.amdon-group:hover {
    box-shadow: inset 0 -40px 0 -1px #ff7121;
    color: #f7f0de;
}
@media only screen and (max-width: 1024px) {
    .about-section p {
        text-align: center;
    }
    .about-section > h3 {
        font-size: 50px;
    }
    .first-article {
        flex-direction: column;
    }
    .right-div > div > img {
        width: 150px;
    }
    .right-div .me-pic {
        width: 150px;
        height: 150px;
    }
    .about-section .first-article .right-div {
        margin-top: 1rem;
    }
    .about-section {
        padding: 5% 8%;
    }
    .second-article {
        margin-top: 5rem;
        flex-direction: column-reverse;
    }
    .second-article .left-div {
        margin-top: 5rem;
    }
    .about-section .first-article .left-div,
    .about-section .first-article .right-div,
    .about-section .second-article .left-div,
    .about-section .second-article .right-div {
       width: 80%;
   }
   .second-article .left-div {
    display: flex;
    justify-content: center;
   }
}
@media only screen and (max-width: 600px) {
    .right-div > div > img {
        width: 110px;
    }
    .right-div .me-pic {
        width: 110px;
        height: 110px;
    }
    .right-div .me-pic::after {
        font-size: 10px;
    }
    .about-section > h3 {
        font-size: 40px;
    }
    .about-section{
        padding: 10% 8%;
    }
    .first-article p {
        font-size: 14px;
    }
    .second-article p {
        font-size: 14px;
    }
    .ref-pic {
        width: 80px;
    }
    .ref-pic:hover {
        transform: scale(250%) translateX(0px);
        z-index: 2;
    }
    .refTwo-pic {
        width: 120px;
    }
    .refTwo-pic:hover {
        transform: scale(250%) translateX(-20px);
    }
    .own-pic {
        width: 150px;
    }
    .own-pic:hover {
        transform: scale(150%) translateX(0px);
        z-index: 2;
    }
    .to {
        left: 200px;
    }
    .about-section .first-article .left-div,
    .about-section .first-article .right-div,
    .about-section .second-article .left-div,
    .about-section .second-article .right-div {
       width: 60%;
   }
   .about-section h4 {
    font-size: 35px;
    left: -90px;
}
}
@media only screen and (max-width: 450px) {

    .right-div > div > img {
        width: 80px;
    }
    .right-div .me-pic {
        width: 80px;
        height: 80px;
    }
    .about-section h4 {
        font-size: 30px;
        left: -70px;
    }
}

/* wave */

.about-curve {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.about-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 39px;
}

.about-curve .shape-fill {
    fill: #ffe8c9;
}