.project-section {
    /* min-height: 100vh; */
    padding: 5% 10%;
    position: relative;
}
.project-section > h3 {
    font-size: 60px;
    font-weight: 400;
    position: relative;
    border-bottom: 2px solid #d50038;
}
.proj {
    display: flex;
    margin-top: 3rem;
}
.project-article {
    margin-top: 3rem;
    margin-bottom: 40px;
}
.project-article .img-div {
    width: 25%;
    position: relative;
}
.project-article a {
    color: #d50038;
    background-image: linear-gradient(#d50038, #d50038);
    background-position: 0 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s;
    text-decoration: none;
}
.project-article a:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}
.img-div img {
    max-width: 300px;
    width: 100%;
    /* height: 100%; */
    box-shadow: rgb(0 0 0 / 15%) 10px 8px 3.2px;
    transition: 0.15s;
}
.img-div img:hover {
    transform: scale(110%);
}
.project-article .desc-div {
    width: 75%;
    padding-left: 5rem;
    padding-right: 1rem;
}
.desc-div > h6 {
    margin-bottom: 2rem;
    color: #555555;
}
.desc-div > h4 {
    margin-bottom: 1rem;
}
.desc-div p {
    text-align: justify;
}
.proj-wrap {
    margin-top: 2rem;
    overflow: auto;
    height: 60vh;
}
@media only screen and (max-width: 1024px) {
    .project-section > h3 {
        font-size: 50px;
    }
}
@media only screen and (max-width: 600px) {
    .project-section > h3 {
        font-size: 40px;
    }
    .project-article .desc-div {
        width: 65%;
        padding-left: 1rem;
    }
    .project-article .img-div {
        width: 35%;
    }
    .project-section {
        font-size: 14px;
    }
}
@media only screen and (max-height: 550px) {
    .project-section {
        min-height: 150vh;
    }

}
/* animation */

.project-section > h3, .proj-wrap {
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}


/* curve */

.project-curve {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.project-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 39px;
}

.project-curve .shape-fill {
    fill: antiquewhite;
}