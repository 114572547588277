.contact-section {
    /* min-height: 100vh; */
    padding: 5% 10%;
    color: #1d1603;
    background: #ffe8c9;
    position: relative;
}
.contact-section > h3 {
    font-size: 60px;
    font-weight: 400;
    position: relative;
    border-bottom: 2px solid #d50139;
}
.contact-article {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
}
.contact-input {
    color: #1d1603;
    width: 100%;
    border-radius: 4px;
    /* border: 3px solid #f7f0de; */
    background: #fef4e6;
    border: none;
    padding: 1em;
    font-size: 1em;
    font-family: "Poppins", sans-serif;
}
.contact-input input {
    height: 44px;
}
.contact-input textarea {
    height: auto;
}
.contact-input::placeholder {
    color: #373737;
}
#toast-container > .toast-success {
    background-color: #ff7121;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  #toast-container > .toast-success:hover {
    background-color: #c24f0c;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
  
.contact-input:focus,  .contact-input:focus::placeholder {
    background: #d50139;
    color: antiquewhite;
    transition: background-color 0.15s linear;
    /* border: 1px solid #f7f0de; */
    outline: none;
}
.contact-input:hover::placeholder {
    color: antiquewhite;
}
.contact-input:hover, .contact-btn:hover {
    color: antiquewhite;
    background: #d50139;
    transition: background-color 0.15s linear;
}
.contact-input:not(:last-child) {
    margin-right: 22px;
}
.mt-2 {
    margin-top: 2rem;
}
.contact-btn {
    border: none;
    height: 44px;
    /* border: 3px solid #373737; */
    background: #fef4e6;
    max-width: 200px;
    width: 200px;
    font-size: 1em;
    color: #1d1603;
    cursor: pointer;
    border-radius: 8px;
}
.contact-btn:focus {
    background: #977725;
    outline: none;
}
.contact-btn:disabled {
    cursor: not-allowed;
}
.leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;

  }
  .contact-form-container, .map-wrap {
      width: 45%;
  }
  .map-wrap {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  .contact-section footer {
    margin-top: 10rem;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    .contact-section > h3 {
        font-size: 50px;
    }
}
  @media only screen and (max-width: 600px) {
    .contact-section > h3 {
        font-size: 40px;
    }
    .contact-section {
        font-size: 14px;
    }
    .contact-article {
        flex-direction: column;
    }
    .contact-form-container, .map-wrap {
        width: 100%;
    }
    .map-wrap {
        height: 300px;
        margin-top: 2rem;
    }
}

/* animation */ 
.contact-section > h3, .contact-input, .contact-btn, .map-wrap{
    transform: translate(0px, 20px);
    opacity: 0;
    transition: opacity 2s ease;
}

/* wave */

.contact-curve {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.contact-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 39px;
}

.contact-curve .shape-fill {
    fill: #ffe8c9;
}