.text-animate {
  display: inline-block;
  opacity: 0;
  animation: fadeInLeft;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

.text-animate-hover {
  min-width: 10px;
  display: inline-block;
  animation-fill-mode: both;

  &:hover {
    animation: flash 1s;
    color: #d60038;
  }
}

.hover-effect {
  animation: flash 1s;
  color: #d60038;
}

@for $i from 1 through 35 {
  ._#{$i} {
    animation-delay: #{$i / 10}s;
    transition: 0.5s;
  }
}