.header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 10px 40px;
    /* background: #1d1603; */
    background: rgb(0 0 0 / 70%);
    backdrop-filter: blur(4px);
    color: #f7f0de;
    z-index: 1;
    position: fixed;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.header-wrap > a {
    text-decoration: none;
}
.text-logo {
    font-family: 'Advent Pro', sans-serif;
    font-size: 34px;
    color: #f7f0de;
}
.text-logo-black {
    font-family: 'Advent Pro', sans-serif;
    font-size: 34px;
    color: #373737;
}
.text-logo > span,.text-logo-black > span {
    color: #ff4040;
}
.header-wrap nav > ul {
    color: #f7f0de;
    list-style-type: none;
    display: flex;
    font-size: 18px;
}
.header-wrap li {
    cursor: pointer;
    background-image: linear-gradient(rgb(247 240 222), #f7f0de);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; /*change after the size immediately*/
}
.header-wrap li:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}
.header-wrap li:not(:last-child) { 
    margin-right: 22px;
 }
 @media only screen and (max-width: 786px) {
     .text-logo {
         font-size: 28px;
     }
     .header-wrap nav > ul {
         font-size: 16px;
     }
 }
 @media only screen and (max-width: 450px) {
     .header-wrap {
         height: 50px;
         padding: 10px;
     }
     .text-logo {
         font-size: 22px;
     }
     .header-wrap nav > ul {
         font-size: 14px;
     }
 }
 @media only screen and (max-height: 450px) {
     .header-wrap {
         height: 40px;
     }
 }