.work-section {
    /* min-height: 100vh; */
    padding: 5% 10%;
    color: #1d1603;
    background: #ffe8c9;
    /* opacity: 0; */
    /* transition: all 2s ease; */
    position: relative;
}
.work-section > h3 {
    font-weight: 400;
    font-size: 60px;
    position: relative;
    border-bottom: 2px solid #d50038;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}

.work-section h2 {
    font-size: 40px;
}
.exp-tree {
    margin: 3rem;
}
.exp-tree a {
    color: #d50038;
    background-image: linear-gradient(#d50038, #d50038);
    background-position: 0 100%;
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s;
    text-decoration: none;
}
.exp-tree a:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}
.year-00 , .year-01, .year-02 {
    display: flex;
}
.div0, .div2 {
    width: 50%;
    margin: 0 3%;
}
.flip {
    background: #d50038;
}
.flop {
    background: #fff;
}
.div1 {
    width: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.line-svg {
    width: min-content;
    height: -webkit-fill-available;
}
.showcase {
    overflow-x: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}
.project1, .project2, .project3 {

    padding: 10px;
    border-radius: 20px;
    text-align: center;
}
.circle-svg {
    width: 30%;
}
.showcase > div:not(:last-child) {
    margin-right: 20px;
}
.res-article {
    margin-top: 3rem;
    margin-bottom: 40px;
}
#classwerkz {
    color: #FF0000;
}
#nodma {
    color: #27AAE1;
}
#nodma span {
    color: #EE4983;
}
#stemwerkz {
    color : #B8A02B;
}
@media only screen and (max-width: 1024px) {
    .work-section > h3 {
        font-size: 50px;
    }
    .circle-svg {
        width: 50%;
    }
}
@media only screen and (max-width: 600px) {
    .work-section > h3 {
        font-size: 40px;
    }
    .circle-svg {
        width: 80%;
    }
    .exp-tree {
        margin: 3rem 0;
        font-size: 14px;
    }
    .work-section h2 {
        font-size: 30px;
    }
    .res-article {
        font-size: 14px;
    }
    .div1 {
        margin: 0 10px;
    }
}

/* animation */

.circle-svg, .line-svg, .div0, .div2, .work-section h2, .res-article {
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}

/* wave */

.work-curve {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.work-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 39px;
}

.work-curve .shape-fill {
    fill: #ffe8c9;
}