@import url('https://fonts.googleapis.com/css2?family=Advent+Pro&family=Courier+Prime&family=Poppins:wght@100;300;400;600&display=swap');
@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;300;400;600&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Sora', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #373737;
  background: antiquewhite;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center{
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  section {
    height: fit-content;
  }
}