.skill-section {
    /* min-height: 100vh; */
    width: 100%;
    padding: 5% 10%;
    /* opacity: 0;
    transition: all 2s ease; */
    position: relative;
}
.skill-wrap {
    display: flex;
}
.skill-section > h3 {
    font-size: 60px;
    font-weight: 400;
    position: relative;
    margin-top: 3rem;
    border-bottom: 2px solid #d50038;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}
.skill-section h2 {
    font-size: 40px;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
}
.circle-svg {
    width: 30%;
}
.skill-list {
    list-style-type: none;
}
.skill-list-article, .education-article {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.skill-wrap .left-div, .skill-wrap .right-div {
    width: 50%;
    transform: translate(0px, 20px);
    opacity: 0;
    transition: all 2s ease;
    animation-delay: 0.2s;
}
.code-box {
    height: 100%;
    border: 3px solid #373737;
    background: #fef4e6;
    font-family: 'Courier Prime', monospace;
    padding: 16px;
    border-radius: 4px;
}
.skill-list li {
    width: fit-content;
    cursor: pointer;
}
.skill-list li:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}
.skill-list li:not(:last-child) {
    margin-bottom: 1.2em;
}
.skill-list li, .uit-link {
    background-image: linear-gradient(rgb(213 0 56), #d50038);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s; /*change after the size immediately*/
}
.uit-link {
    text-decoration: none;
    color: #d50038;
}
.uit-link:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 2px;
}
.education-article div{
    display: flex;
    justify-content: space-between;
    background: #fef4e6;
    padding: 10px;
    border-radius: 10px;
    opacity: 0;
    transition: all 2s ease;
    animation-delay: 0.2s;
}
.education-article > div {
    border: 1px solid #373737;
}
.uit-img {
    height: 60px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}
.ncc-img {
    height: 60px;
}
.education-article > div:not(:last-child) {
    margin-bottom: 3rem;
}
@media only screen and (max-width: 1024px) {
    .skill-section > h3 {
        font-size: 50px;
    }
    .circle-svg {
        width: 50%;
    }
}
@media only screen and (max-width: 600px) {
    .skill-section > h3 {
        font-size: 40px;
    }
    .skill-list li, .code-box, .education-article {
        font-size: 14px;
    }
    .skill-section h2 {
        font-size: 30px;
    }
    .uit-img, .ncc-img {
        height: 40px;
    }
}


/* curve */

.skill-curve {
    position: absolute;
    top: -36px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.skill-curve svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 39px;
}

.skill-curve .shape-fill {
    fill: antiquewhite;
}